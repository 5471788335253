


















import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import CodeEditor from "@/components/CodeEditor.vue";

const paymentCalculationNamespace = namespace("PaymentCalculation");

@Component({ components: { CodeEditor } })
export default class PaymentConfig extends Vue {
  isLoading = false;

  @paymentCalculationNamespace.Getter("all")
  paymentCalculation!: any;

  @paymentCalculationNamespace.Action("fetchAll")
  fetchPaymentCalculation!: () => Promise<void>;

  @paymentCalculationNamespace.Action("update")
  update!: (paymentCalculation: any) => Promise<void>;

  async doSave() {
    this.isLoading = true;
    await this.update(this.paymentCalculation);
    this.isLoading = false;
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchPaymentCalculation();
    this.isLoading = false;
  }

  created() {
    this.doFetchAll();
  }
}
